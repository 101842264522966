* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.admin-button {
  float: right;
  color: #fff;
  background-color: #df4b00;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  text-decoration: none;
}

.page-header {
  color: #fff;
  text-align: center;
  background-color: #df4b00;
  font-size: 14px;
}

.page-main {
  flex: 1;
}

.page-footer {
  color: #fff;
  text-align: right;
  background-color: #373737;
  border-top: 16px solid #7a7a7a;
  margin-top: 32px;
  padding: 24px;
}

.page-footer a.hash-link {
  color: #7a7a7a;
  margin-left: 32px;
}

.page-header .link {
  color: #fff;
  text-transform: uppercase;
  margin-left: 32px;
}

.page-footer .link {
  color: #f8f8f8;
  margin: 16px;
}

.gallery {
  background-color: #fff;
  width: 380px;
  overflow-x: hidden;
}

.gallery .preview {
  cursor: pointer;
  object-fit: cover;
  border: 1px solid #d8d8d8;
  width: 380px;
  height: 200px;
}

.gallery .minis {
  white-space: nowrap;
  width: 380px;
  overflow-x: auto;
}

.gallery .mini {
  cursor: pointer;
  object-fit: cover;
  border: 1px solid #d8d8d8;
  width: 104px;
  height: 68px;
}

.gallery .mini + .mini {
  margin: 6px 4px;
}

.description-blok {
  margin-top: 32px;
}

.description-blok .content {
  padding: 8px 32px 0;
}

.table td {
  border-bottom: 1px dashed #ccc;
  padding: 4px 8px;
  font-size: 14px;
}

.ProductCategoryBox {
  cursor: pointer;
  x-height: 272px;
  background: radial-gradient(circle, #e6e6e6 0%, #d0d0d0 100%);
  border: 12px solid #fff;
  width: 340px;
  margin: 8px;
  display: inline-block;
  position: relative;
}

.ProductCategoryBox .img {
  border-bottom: 1px solid orange;
  width: 340px;
  height: 200px;
}

.ProductCategoryBox .title {
  color: #333;
  margin: 8px;
  font-size: 22px;
  font-weight: bold;
}

.ProductCategoryBox .subtitle {
  margin: 4px 8px 12px;
  font-size: 15px;
  font-style: italic;
}

.ProductCategoryBox .price {
  opacity: .8;
  color: #222;
  background: #ccc;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  font-style: italic;
  position: absolute;
  top: 10px;
  right: 10px;
}

.hash-link {
  color: #424242;
  font-weight: 200;
}

.hash-link + .hash-link {
  margin-left: 24px;
}

.feature {
  color: #aaa;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid orange;
  border-right: 1px solid orange;
  margin: 8px 2px 8px 8px;
  padding: 8px 24px;
  font-size: 14px;
  display: inline-block;
  transform: skew(-10deg);
}

.feature:hover {
  color: #fff;
  background-color: #f59239;
}

.ClearableTextInput {
  display: inline-block;
  position: relative;
}

.x {
  cursor: pointer;
  color: #ff5b5b;
  font-size: 28px;
  position: absolute;
  top: -2px;
  right: 8px;
}

.MainMenu {
  z-index: 100;
  text-align: center;
  width: 100%;
  margin-bottom: 36px;
  padding: 3px 0;
  position: relative;
}

.page-width {
  width: 660px;
}

.dark {
  background: linear-gradient(#424242 0%, #313131 100%);
}

.selected {
  color: #fff !important;
  background: linear-gradient(#f59239 0%, #ff7b0d 100%) !important;
}

.tabs {
  border-bottom: 2px dotted gray;
}

.tab {
  color: #222;
  cursor: pointer;
  background: #fff;
  border: 1px solid gray;
  border-bottom: none;
  margin-right: 4px;
  padding: 12px 20px;
  font-weight: normal;
  text-decoration: none;
  display: inline-block;
}

.InputRow {
  border-bottom: 1px dotted #bbb;
  width: 100%;
  margin: 8px;
  padding-bottom: 8px;
  display: flex;
}

.fixed-width {
  text-align: left;
  min-width: 180px;
  font-size: 14px;
}

.flexible-width {
  text-align: left;
  flex: 1;
}

div.radio {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

div.radio label span {
  white-space: nowrap;
  margin-left: 8px;
}

.add-field-box a {
  margin-right: 16px;
}

.markdown {
  margin: 0;
  padding: 0;
}

.markdown p:not(:first-child) {
  text-indent: 16px;
  text-align: justify;
  margin: 0;
  padding: 0;
}

.markdown-edit {
  float: left;
  text-align: left;
  width: 50%;
  padding: 12px;
}

.markdown-edit-preview {
  float: right;
  text-align: left;
  border: 1px solid #888;
  width: 50%;
  height: auto;
  padding: 12px;
}

.features {
  white-space: nowrap;
  scrollbar-width: none;
  overflow-x: scroll;
}

.entry {
  text-align: left;
  background: #f8f8f8;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
}

.MiniBasketBox {
  z-index: 100;
  color: #333;
  text-align: left;
  background: #f6f6f6;
  border: 4px solid orange;
  border-radius: 4px;
  height: auto;
  padding: 16px;
  position: absolute;
  top: 38px;
  right: 0;
}

@media (pointer: coarse) {
  .container {
    flex-direction: column;
  }

  .left {
    order: 2;
    width: 100%;
  }

  .right {
    order: 1;
    width: 100%;
  }

  .page-header {
    background-color: #ff732d;
    padding: 8px;
    font-size: 12px;
  }
}
/*# sourceMappingURL=index.ac8eecc8.css.map */
