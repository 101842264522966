 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     /* color: #fff; */
 }

 .admin-button {
     float: right;
     font-size: 16px;
     background-color: #df4b00;
     color: #fff;
     padding: 8px 16px;
     border-radius: 4px;
     text-decoration: none;
 }

 /* html,
body,
.root {
    height: 100%;
    background: radial-gradient(circle, rgb(248, 247, 247) 0%, rgb(232, 232, 232) 100%);
}

body,
.root {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
} */

 .page-header {
     background-color: #df4b00;
     color: #fff;
     text-align: center;
     font-size: 14px;
 }

 .page-main {
     flex: 1;
 }

 .page-footer {
     background-color: #373737;
     color: #fff;
     padding: 24px;
     text-align: right;
     margin-top: 32px;
     border-top: 16px solid #7a7a7a;
 }

 .page-footer a.hash-link {
     color: #7a7a7a;
     margin-left: 32px;
 }

 .page-header .link {
     color: #fff;
     margin-left: 32px;
     text-transform: uppercase;
 }

 .page-footer .link {
     color: #f8f8f8;
     margin: 16px;
 }

 .gallery {
     width: 380px;
     background-color: #fff;
     overflow-x: hidden;
 }

 .gallery .preview {
     height: 200px;
     width: 380px;
     cursor: pointer;
     object-fit: cover;
     border: 1px solid #d8d8d8;
 }

 .gallery .minis {
     overflow-x: auto;
     white-space: nowrap;
     width: 380px;
 }

 .gallery .mini {
     width: 104px;
     height: 68px;
     cursor: pointer;
     object-fit: cover;
     border: 1px solid #d8d8d8;
 }

 .gallery .mini+.mini {
     margin: 6px 4px;
 }

 /* 
.header {
    margin-top: 40px;
    margin-bottom: 16px;
} */

 .description-blok {
     margin-top: 32px;
 }

 .description-blok .content {
     padding: 8px 32px 0;
 }

 .table td {
     padding: 4px 8px;
     border-bottom: 1px dashed #ccc;
     font-size: 14px;
 }

 /* .row {
    position: relative;
    width: 100%;
    text-align: center;
} */

 /* .centered {
    margin: auto;
    position: relative;
    display: inline-block;
    width: 840px;
} */

 /* .header .centered {
    text-align: left;
    font-size: 18px;
    border-bottom: 1px solid #aaa;
    font-weight: bold;
} */

 .ProductCategoryBox {
     display: inline-block;
     position: relative;
     cursor: pointer;
     border: 1px solid #ccc;
     margin: 8px;
     width: 340px;
     x-height: 272px;
     background: #ddd;
     background: radial-gradient(circle, rgba(230, 230, 230, 1) 0%, rgba(208, 208, 208, 1) 100%);
     border: 12px solid #fff;
 }

 .ProductCategoryBox .img {
     width: 48%;
     height: 200px;
     width: 340px;
     border-bottom: 1px solid orange;
 }

 .ProductCategoryBox .title {
     margin: 8px;
     font-size: 22px;
     font-weight: bold;
     color: #333;
 }

 .ProductCategoryBox .subtitle {
     font-size: 15px;
     font-style: italic;
     margin: 4px 8px 12px;
 }

 .ProductCategoryBox .price {
     font-style: italic;
     padding: 8px;
     position: absolute;
     top: 10px;
     right: 10px;
     opacity: 0.8;
     font-size: 14px;
     color: #222;
     background: #ccc;
     padding: 4px 8px;
     border-radius: 8px;
 }

 .hash-link {
     color: #424242;
     font-weight: 200;
 }

 .hash-link+.hash-link {
     margin-left: 24px;
 }

 .feature {
     display: inline-block;
     padding: 8px 24px;
     margin: 8px;
     margin-right: 2px;
     background-color: #fff;
     color: #aaa;
     font-size: 14px;
     border-bottom: 1px solid orange;
     border-right: 1px solid orange;
     cursor: pointer;
     transform: skew(-10deg);
 }

 .feature:hover {
     background-color: #f59239;
     color: #fff;
 }

 .ClearableTextInput {
     display: inline-block;
     position: relative;
 }

 .x {
     font-size: 28px;
     cursor: pointer;
     position: absolute;
     top: -2px;
     right: 8px;
     color: #ff5b5b;
 }

 /* button {
    color: #fff !important;
    border: none !important;
} */

 .MainMenu {
     width: 100%;
     padding: 3px 0;
     z-index: 100;
     margin-bottom: 36px;
     position: relative;
     text-align: center;
 }

 .page-width {
     width: 660px;
 }

 .dark {
     background: linear-gradient(to bottom, #424242 0%, #313131 100%);
 }

 .selected {
     color: #fff !important;
     background: linear-gradient(to bottom, #f59239 0%, #ff7b0d 100%) !important;
 }

 .tabs {
     border-bottom: 2px dotted gray;
 }

 .tab {
     display: inline-block;
     margin-right: 4px;
     padding: 12px 20px;
     color: #222;
     background: #fff;
     border-top: 1px solid gray;
     border-left: 1px solid gray;
     border-right: 1px solid gray;
     border-bottom: none;
     cursor: pointer;
     font-weight: normal;
     text-decoration: none;
 }

 .InputRow {
     display: flex;
     width: 100%;
     margin: 8px;
     border-bottom: 1px dotted #bbb;
     padding-bottom: 8px;
 }

 .fixed-width {
     min-width: 180px;
     text-align: left;
     font-size: 14px;
 }

 .flexible-width {
     flex: 1;
     text-align: left;
 }

 .flexible-width input,
 .flexible-width select {}


 div.radio {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
 }

 /* label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
} */

 div.radio label span {
     white-space: nowrap;
     margin-left: 8px;
 }



 .add-field-box a {
     margin-right: 16px;
 }

 .markdown {
     margin: 0;
     padding: 0;
 }

 .markdown p:not(:first-child) {
     margin: 0;
     padding: 0;
     text-indent: 16px;
     text-align: justify;
 }

 .markdown-edit {
     width: 50%;
     float: left;
     text-align: left;
     padding: 12px;
 }

 .markdown-edit-preview {
     width: 50%;
     float: right;
     border: 1px solid #888;
     text-align: left;
     padding: 12px;
     height: auto;
 }

 .features {
     overflow-x: scroll;
     white-space: nowrap;
     scrollbar-width: none;
 }

 /* 
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}

.container .hash-link {
    color: #fff;
}

.left,
.center,
.right {
    width: 50%;
    padding: 20px;
    color: #fff;
}

.left {
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase;
    color: #fff;
}

.right {
    text-align: right;
    white-space: nowrap;
} */

 .entry {
     width: 100%;
     padding: 8px;
     margin-bottom: 8px;
     background: #f8f8f8;
     border: 1px solid #d8d8d8;
     border-radius: 8px;
     text-align: left;
 }

 .MiniBasketBox {
     position: absolute;
     top: 38px;
     right: 0px;
     z-index: 100;
     background: #f6f6f6;
     color: #333;
     height: auto;
     border: 4px solid orange;
     border-radius: 4px;
     text-align: left;
     padding: 16px;
 }

 @media (pointer: coarse) {

     /* html {
        border: 2px solid red;
    } */

     .container {
         flex-direction: column;
     }

     .left {
         order: 2;
         width: 100%;
     }

     .right {
         order: 1;
         width: 100%;
     }

     .page-header {
         background-color: #ff732d;
         padding: 8px;
         font-size: 12px;
     }
 }